export const environment = {
    production: true,
    apiUrl: "https://api.kobize.ekowitryna.pl/api",
    keycloakUrl: "https://auth.mobiletract.com",
    realm: "bdo-mobile",
    clientId: "ekowitryna_kobize",
    googleTagManagerId: 'GTM-KTDGNR8B',
    googleTagManagerPreview: 'env-1',
    googleTagManagerAuth: '_r3Xn4xFjw8gzRpNXBhONw'
};
