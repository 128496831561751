import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { AvatarModule } from 'primeng/avatar';
import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './components/breadcumb/breadcrumb.component';
import { MenuModule } from 'primeng/menu';

@NgModule({
  declarations: [
    NavigationBarComponent,
    HeaderProfileComponent,
    BreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MenubarModule,
    AvatarModule,
    ButtonModule,
    MenuModule,
  ],
  exports: [
    NavigationBarComponent,
    BreadcrumbComponent,
  ],
})
export class SharedModule { }
