<div class="bg-bunker-500 sticky top-0 z-30 w-full">
  <div class="mx-auto container">
    <div class="relative flex h-16 items-center">
      <img
        class="block h-8 w-auto lg:hidden"
        src="../../../../assets/img/logo_sygnet.svg"
        alt="Ekowitryna.pl"
      />
      <img
        class="hidden h-8 w-auto lg:block"
        src="../../../../assets/img/logo_large.svg"
        alt="Ekowitryna.pl"
      />
      <span
        class="bg-blue-100 text-blue-800 select-none text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-2"
        >KOBiZE</span
      >
      <app-header-profile class="right-0 absolute"></app-header-profile>
    </div>
  </div>
</div>
