<p-button *ngIf="!authenticated" pButton type="button"
        class="p-button-sm bg-eucalyptus-500 rounded-full border-0 hover:bg-eucalyptus-600 p-2 text-xs text-white focus:outline-none"
        label="Zaloguj się" icon="pi pi-sign-in" iconPos="left" (click)="login()">
</p-button>

<p-button *ngIf="authenticated" [text]="true" class="flex rounded-full" (onClick)="menu.show($event)">
  <span class="sr-only">Open user menu</span>
  <img class="h-8 w-8 rounded-full" src="https://ui-avatars.com/api/?size=128&background=random&name={{getFullName() }}" alt="" />
</p-button>
<p-menu #menu [model]="items" appendTo="body" [popup]="true"></p-menu>
