import { Component, Input, OnInit } from '@angular/core';

interface BreadcrumbItem {
  label: string;
  route?: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  @Input() items: BreadcrumbItem[] = [];

  get firstItem(): BreadcrumbItem | undefined {
    return this.items[0];
  }

  get betweenItems(): BreadcrumbItem[] {
    return this.items.length > 2 ? this.items.slice(1, this.items.length - 1) : [];
  }

  get lastItem(): BreadcrumbItem | undefined {
    return this.items.length > 1 ? this.items[this.items.length - 1] : undefined;
  }

  constructor() { }

  ngOnInit(): void {
    if (this.items.length < 1) {
      throw new Error('Breadcrumb component must have at least one item');
    }
  }
}
