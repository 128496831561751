import { KeycloakProfile } from 'keycloak-js';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUser from '../../../core/state/user/user.reducer';
import { KeycloakService } from 'keycloak-angular';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
})
export class HeaderProfileComponent implements OnInit {

  authenticated = false;

  profile: KeycloakProfile | null = null;

  items: MenuItem[] = [
    { label: 'Wyloguj', icon: 'pi pi-sign-out', command: () => this.keycloakService.logout() }
  ];

  constructor(
    private store: Store<fromUser.UserState>,
    private keycloakService: KeycloakService,
    ) {
  }

  ngOnInit() {
    this.authenticated = this.keycloakService.isLoggedIn();
    this.keycloakService.loadUserProfile().then(profile => {
      this.profile = profile;
    });
  }

  getFullName() {
    return this.profile?.firstName + ' ' + this.profile?.lastName;
  }

  login(){
    this.keycloakService.login();
  }
}
